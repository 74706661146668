import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M47.1442 38.0447C46.9737 36.532 46.2706 35.3601 45.0348 34.5291C43.799 33.6875 42.2436 33.2667 40.3686 33.2667C39.0263 33.2667 37.8651 33.4798 36.8849 33.9059C35.9048 34.3214 35.1431 34.8967 34.5998 35.6317C34.0671 36.3562 33.8008 37.1818 33.8008 38.1087C33.8008 38.8864 33.9819 39.5575 34.3441 40.1222C34.717 40.6868 35.2017 41.1609 35.7983 41.5444C36.4055 41.9173 37.0554 42.2315 37.7479 42.4872C38.4403 42.7322 39.1062 42.9347 39.7454 43.0945L42.9414 43.9254C43.9854 44.1811 45.0561 44.5273 46.1534 44.9641C47.2507 45.4009 48.2681 45.9762 49.2056 46.69C50.1431 47.4038 50.8995 48.288 51.4748 49.3427C52.0607 50.3974 52.3537 51.6598 52.3537 53.13C52.3537 54.9837 51.8743 56.6296 50.9155 58.0678C49.9673 59.506 48.5877 60.6406 46.7766 61.4716C44.9762 62.3026 42.7976 62.718 40.2408 62.718C37.7905 62.718 35.6705 62.3292 33.8807 61.5515C32.0909 60.7738 30.69 59.6712 29.6779 58.2436C28.6658 56.8054 28.1065 55.1009 28 53.13H32.9538C33.0497 54.3125 33.4332 55.2979 34.1044 56.0863C34.7862 56.864 35.6545 57.4446 36.7092 57.8281C37.7745 58.201 38.9411 58.3874 40.2088 58.3874C41.6044 58.3874 42.8455 58.169 43.9322 57.7322C45.0295 57.2848 45.8924 56.6669 46.521 55.8786C47.1495 55.0795 47.4638 54.1474 47.4638 53.082C47.4638 52.1126 47.1868 51.3189 46.6328 50.701C46.0895 50.0831 45.3491 49.5717 44.4116 49.1669C43.4847 48.7621 42.4354 48.4052 41.2635 48.0962L37.3963 47.0415C34.7756 46.3278 32.6982 45.2784 31.1641 43.8935C29.6406 42.5085 28.8789 40.6761 28.8789 38.3963C28.8789 36.5107 29.3903 34.8647 30.413 33.4585C31.4357 32.0522 32.8207 30.9602 34.5678 30.1825C36.315 29.3942 38.2859 29 40.4805 29C42.6964 29 44.6513 29.3888 46.3452 30.1665C48.0497 30.9442 49.392 32.0149 50.3722 33.3786C51.3523 34.7315 51.8636 36.2869 51.9062 38.0447H47.1442Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
